@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Black.woff) format("woff"), url(/fonts/Charlie_Display-Black.woff2) format("woff2");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Bold.woff) format("woff"), url(/fonts/Charlie_Display-Bold.woff2) format("woff2");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Light.woff) format("woff"), url(/fonts/Charlie_Display-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Regular.woff) format("woff"), url(/fonts/Charlie_Display-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Semibold.woff) format("woff"), url(/fonts/Charlie_Display-Semibold.woff2) format("woff2");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Black_Italic.woff) format("woff"), url(/fonts/Charlie_Display-Black_Italic.woff2) format("woff2");
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Bold_Italic.woff) format("woff"), url(/fonts/Charlie_Display-Bold_Italic.woff2) format("woff2");
  font-weight: 600;
  font-style: italic
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Italic.woff) format("woff"), url(/fonts/Charlie_Display-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Light_Italic.woff) format("woff"), url(/fonts/Charlie_Display-Light_Italic.woff2) format("woff2");
  font-weight: 300;
  font-style: italic
}

@font-face {
  font-family: 'Charlie Display';
  src: url(/fonts/Charlie_Display-Semibold_Italic.woff) format("woff"), url(/fonts/Charlie_Display-Semibold_Italic.woff2) format("woff2");
  font-weight: 500;
  font-style: italic
}

@font-face {
  font-family: 'Charlie Text';
  src: url(/fonts/Charlie_Text-Regular.woff) format("woff"), url(/fonts/Charlie_Text-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Charlie Text';
  src: url(/fonts/Charlie_Text-Semibold.woff) format("woff"), url(/fonts/Charlie_Text-Semibold.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Charlie Text';
  src: url(/fonts/Charlie_Text-Italic.woff) format("woff"), url(/fonts/Charlie_Text-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
}

h1 {
  font-family: 'Charlie Display';
  font-weight: 500;
  font-size: 52px;
  line-height: 60px;
  color: #253858;
  margin-bottom: 24px;
  margin-top: 96px;
}

h2 {
  font-family: 'Charlie Display';
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: #253858;
  margin-bottom: 24px;
  margin-top: 32px;
}

h3 {
  font-family: 'Charlie Display';
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #253858;
  margin-bottom: 24px;
  letter-spacing: .3px;
}

h4 {
  font-family: 'Charlie Display';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #253858;
  margin-bottom: 24px;
  letter-spacing: .3px;
}

h5 {
  font-family: 'Charlie Display';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #253858;
  margin-bottom: 24px;
  text-transform: uppercase;
  letterspacing: 1px;
}

h6 {
  font-family: 'Charlie Display';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #253858;
  margin-bottom: 16px;
  text-transform: uppercase;
  letterspacing: 1px;
}

p {
  font-family: 'Charlie Text';
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #091E42;
  margin-bottom: 24px;
  max-width: 600px;
}

p.oversized {
  font-family: 'Charlie Display';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: .3px;
  color: #42526E;
  margin-bottom: 24px;
}

p.long {
  font-family: 'Charlie Text';
  font-feature-settings: "ss01";
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #091E42;
  margin-bottom: 24px;
}

p.meta {
  font-family: 'Charlie Text';
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #091E42;
  margin-bottom: 20px;
}

p.code {
  font-family: "SF Mono", "Segoe UI Mono", "Roboto Mono", "Ubuntu Mono", Menlo, Courier, monospace;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #EBECF0;
  background-color: #091E42;
  padding: 24px 40px 32px 40px;
}

a {
  color: #0052CC;
  font-weight: 500;
}

video {
  margin-bottom: 8px;
  max-width: 768px;
  width: 100%;
}

.header {
  position: absolute;
  left: 16px;
  top: 20px;
}

.hero-image {
  max-height: 336px;
  width: 100%;
}

.logo-link:hover {
  opacity: 0.8;
}

.segment {
  padding: 40px 0;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 24px;
}

.centered {
  align-items: center;
  text-align: center;
}

.centered-small {
  text-align: left;
}

.mod-blue, .mod-blue-gradient {
  background-color: #0079BF;
  color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #fff;
  }
}

.mod-blue-gradient {
  background-image: linear-gradient(to bottom right, #007ABF , #5068C5);
}

.mod-gray {
  background-color: #dfe1e6;
}

.mod-light-blue {
  background-color: #E4F0F6;
}

.two-column-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 12px;
  align-items: center;
  justify-items: center;
  margin-bottom: 12px;
  margin-top: 24px;
}

.two-column-grid.symmetric {
  grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
}

.three-column-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: center;
  margin-top: 24px;
}

.wide-card {
  width: 300px;
}

.lowered {
  margin-top: 12px;
}

@media only screen and (max-width: 860px) {
  .two-column-grid {
    display: flex;
  }

  .two-column-grid>div:first-child {
    margin-right: 12px;
  }

  .gist {
    max-width: 100%;

    & table {
      table-layout: fixed;
    }

    & td.js-line-number {
      width: 8px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 680px) {
  .container {
    padding: 0 8px;
  }

  .centered-small {
    text-align: center;
  }

  .two-column-grid, .two-column-grid.symmetric {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 12px;
  }

  .two-column-grid>div:first-child {
    margin-right: 0;
  }

  .three-column-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 12px;
  }

  .gist {
    max-width: 100%;

    & table {
      table-layout: fixed;
    }

    & td.js-line-number {
      width: 8px;
      text-align: center;
    }
  }
}
